function addLibraries(libs, callback) {

    if (libs) {
        if (!window.lazyLoadLibraries) {
            window.lazyLoadLibraries = [];
        }

        if (libs instanceof Array) {
            for (var i = 0; i < libs.length; i++) {
                if (window.lazyLoadLibraries.indexOf(libs[i]) === -1) {
                    window.lazyLoadLibraries.push(libs[i]);
                }
            }
        } else {
            if (window.lazyLoadLibraries.indexOf(libs) === -1) {
                window.lazyLoadLibraries.push(libs);
            }
        }
    }

    if (callback) {
        if (!window.lazyLoadCallbacks) {
            window.lazyLoadCallbacks = [];
        }

        window.lazyLoadCallbacks.push(callback);
    }
}

function loadLazyLoadLibraries() {
    var _arr = [];
    if (window.lazyLoadLibraries) {
        _arr = jQuery.map(window.lazyLoadLibraries, function (src) {
            var deferred = jQuery.Deferred();
            var scriptElem = document.createElement('script');
            scriptElem.setAttribute('src', src);
            scriptElem.defer = true;
            scriptElem.async = false;
            scriptElem.onload = deferred.resolve;
            document.head.appendChild(scriptElem);
            return deferred.promise();
        });
    }
    _arr.push(jQuery.Deferred(function (deferred) {
        jQuery(deferred.resolve);
    }));

    return jQuery.when.apply(jQuery, _arr);
}

function importLazyLoadLibraries() {
    if (window.lazyLoadLibraries) {
        loadLazyLoadLibraries().then(function () {
            if (window.lazyLoadCallbacks) {
                for (var i = 0; i < window.lazyLoadCallbacks.length; i++) {
                    window.lazyLoadCallbacks[i]();
                }
            }
        });
    }
}